<template>
<div id="success">
   <div class="success-wrap">
     <i class="el-icon-circle-check"></i>
     <p>支付成功</p>
     <div class="btn" @click="jump">查看订单</div>
   </div>
</div>
</template>

<script>
export default {
  name: "success",
  data() {
    return {
      type: ''
    }
  },
  mounted() {
    this.type  = this.$route.query.type
  },
  methods: {
   jump(){debugger
     if(this.type === 1){
       this.$router.push({
         path: '/shop'
       })
     }else if(this.type === 2){
       this.$router.push({
         path: '/market',
         query:{
           type:2
         }
       })
     }

   }
  }
};
</script>

<style lang="scss" scoped>
#success {
  width: 100%;
  min-height: 200px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
  padding-top: 24px;
  padding-bottom: 24px;
}
.success-wrap{
  width: 1000px;
  height: 400px;
  background: #fff;
  margin: 0 auto;
  text-align: center;
 i{
  font-size: 100px;
  color: #07c160;
  margin-top: 70px;
 }
 p{
  margin-top: 20px;
 }
 .btn{
  display: inline-block;
  width:300px;
  height: 42px;
  line-height:42px;
  background: #0077FF;
  box-shadow: 0px 4px 17px 0px rgba(0,119,255,0.41);
  border-radius: 5px;
  text-align: center;
  color: #fff;
  margin-top:55px;
  font-size: 14px;
  cursor: pointer;
 }
}

</style>

